<template>
  <section style="background: #FFFFFF;">
    <!--组织展示-->
    <!-- <el-col :span="4">
      <div class="orgbtndivtree">
        <el-divider>部门</el-divider>
        <el-cascader :props="props"
                     v-model="cascadervalue"
                     ref="department"
                     clearable
                     size="mini"
                     :show-all-levels="false"
                     expand-trigger="hover"
                     popper-class="depc"
                     @change="cascaderchange"></el-cascader>
      </div>
      <div class="orgtree">
        <el-table :data="departusers"
                  highlight-current-row
                  :row-style="{height:'40px'}"
                  :cell-style="{padding:'0px'}"
                  empty-text="请选择部门"
                  :header-cell-style="{background:'#dce4f1',color:'#000000'}">
          <el-table-column type="index"
                           width="70px"></el-table-column>
          <el-table-column label="姓名"
                           prop="UserNameClaim"
                           align="left"></el-table-column>
        </el-table>
        <el-pagination v-if="Pagination"
                       align='center'
                       @current-change="handleCurrentChangeDepart"
                       :current-page="DepartcurrentPage"
                       :page-size="DepartpageSize"
                       layout="prev, pager, next"
                       :page-count="Departtotal"
                       :total="Departtotaldata">
        </el-pagination>
      </div>
    </el-col> -->
    <!--工作数据展示-->
    <el-col :span="24">
      <div>
        <el-form :inline="true"
                 style="width: 100%;overflow-x: auto;white-space: nowrap;margin-left: 10px;margin-top: 10px;padding-bottom:0px;"
                 size="mini">
          <el-form-item label="日期：">
            <el-tooltip placement="top" content="前一天" effect="light">
              <el-button icon="el-icon-back" style="margin-right:3px" @click="handleChangeDay(1)" circle></el-button>
            </el-tooltip>
            <el-date-picker v-model="lookdate"
                            type="date"
                            @change="handleDateChange"
                            :clearable="false"
                            placeholder="选择查看日期"></el-date-picker>
            <el-tooltip placement="top" content="后一天" effect="light">
              <el-button icon="el-icon-right" style="margin-left:3px" @click="handleChangeDay(2)" circle></el-button>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="部门">
            <el-button type="default" @click="handleChooseDpt">{{btntext}}</el-button>
            <!-- <el-cascader :props="props"
                         v-model="cascadervalue"
                         ref="department"
                         clearable
                         size="mini"
                         :show-all-levels="false"
                         expand-trigger="hover"
                         popper-class="depc"
                         @change="cascaderchange"></el-cascader> -->
          </el-form-item>
          <!-- <el-form-item>
            <el-pagination v-if="Pagination"
                           small
                           @current-change="handleCurrentChangeDepart"
                           :current-page="DepartcurrentPage"
                           :page-size="DepartpageSize"
                           layout="prev, pager, next"
                           :page-count="Departtotal"
                           :total="Departtotaldata">
            </el-pagination>
          </el-form-item> -->
        </el-form>

        <!-- <el-form :inline="true" style="width: 100%;overflow-x: auto;white-space: nowrap;" size="mini">
                    <el-form-item label="年度计划:">
                        <el-select v-model = "YearPlanTask" @change="YearPlanchange" clearable>
                            <el-option v-for = "item in YearPlanTaskList" :key = "item.Id" :value = "`${item.YearPlanId}|${item.TaskId}|${item.WorkItem}`" :label = "item.WorkItem">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="工作项目:">
                        <el-input v-model="workItem" placeholder="请输入" maxlength="10" show-word-limit clearable  style="width: 70%;"></el-input>
                    </el-form-item>
                    <el-form-item label="工作类型:" style="margin-left: -60px;">
                        <el-select
                            v-model="taskClassify"
                            style="width: 50%;"
                            clearable
                         >
                            <el-option
                                v-for="item in ClassifyModules"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="任务状态:" style="margin-left: -100px;">
                        <el-select
                            v-model="taskStatus"
                            style="width: 50%;"
                         >
                            <el-option
                                v-for="item in StatusModules"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item> 
                </el-form>
                <el-form :inline="true" style="margin-left: 100px" size="mini">      
                    <el-form-item label="任务进度:" style="margin-left: -100px;"> 
                        <el-input v-model="progressValue" placeholder="请输入" type="number" min= 0 max = 100></el-input>
                    </el-form-item>
                    <el-form-item label="创建日期:" style="margin-left: -5px;">
                        <el-date-picker
                            v-model="madedate"
                            style="width: 70%;"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item style="margin-left: -100px;">
                        <el-button size="mini" type="goon" round icon="el-icon-search" @click="getManagerChecks">查询</el-button>
                    </el-form-item> 
               </el-form> -->
      </div>
      <el-divider>任务概览分析</el-divider>
      <div v-show="isdepartment">
        <!-- <RaddarCharttool :key="key" :departid = "departid"></RaddarCharttool> -->
        <span style="font-size: 12px;color: red;">当天各人员操作任务数量占比</span>
        <PeopleBarCharttool @call="call($event)"
                            :key="key"
                            :departid="departid"></PeopleBarCharttool>
        <!-- <span style="font-size: 12px;color: red;">各人员任务执行进度占比</span>
                <BarCharttool :key="key" :departid = "departid"></BarCharttool>
                <span style="font-size: 12px;color: red;">各人员任务完成日期及数量占比</span>
                <PeopleTaskBarCharttool :key="key" :departid = "departid"></PeopleTaskBarCharttool>
                 -->
        <el-form>
          <el-form-item>
            <el-button-group style="margin-left:38%;" v-show="1<Departtotal">
              <el-button  size="mini" type="primary"
                         icon="el-icon-arrow-left"
                         @click="handleCurrentChangeDepart(-1)">上一页</el-button>
              <el-button  size="mini" type="primary"
                         @click="handleCurrentChangeDepart(1)">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
            </el-button-group>
          </el-form-item>
        </el-form>
        <el-table :data="users"
                  highlight-current-row
                  row-key="Id"
                  lazy
                  :indent="30"
                  :load="load"
                  :tree-props="{children: 'children',hasChildren: 'IsHasChildren'}"
                  :fit="true"
                  :row-style="{height:'40px'}"
                  :cell-style="{padding:'0px'}"
                  :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                  @row-dblclick="checkInfo"
                  @cell-mouse-enter="tabmouseEnter"
                  @cell-mouse-leave="tabmouseLeave"
                  style="width: 100%;height:100%;">
          <el-table-column type="index"></el-table-column>
          <el-table-column label="工作项目" prop="WorkItem" min-width="170px" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-button @click="handleCollect(scope.row)" style="margin-right:3px;color:#FF9800;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null"  :title="scope.row.CollectId==null?'特别关注':'已特别关注'"  type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button><span :style="{'cursor':'default'}">{{scope.row.WorkItem}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="具体描述及要求" prop="WorkDescription"></el-table-column> -->
          <el-table-column label="计划完成时间"
                           prop="PlanComplateTime"
                           min-width="110px"
                           :formatter="formatPlanComplateTime">
            <template slot-scope="scope">
              <div v-if="new Date()>new Date(new Date(new Date(scope.row.PlanComplateTime).toLocaleDateString()).getTime()+24*60*60*1000-1)&&scope.row.TaskProgressValue<100">
                <font color=red>{{formatPlanComplateTime(scope.row)}}</font>
              </div>
              <div v-else>
                <font color=black>{{formatPlanComplateTime(scope.row)}}</font>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="责任人" prop="PersonOfDuty" min-width="70px"></el-table-column> -->
          <el-table-column label="任务状态"
                           prop="Status"
                           align="center"
                           min-width="100px">
            <template slot-scope="scope">
              <el-tooltip placement="top">
                <!-- <div slot="content">
                                            已完成{{  scope.row.TaskProgressValue }}%
                                        </div>
                                        <div slot="content" v-if="scope.row.Status == 1 || scope.row.Status == 4 || scope.row.Status == 5">
                                            审核中
                                        </div> -->
                <div slot="content"
                     v-if="scope.row.Status == 0 || scope.row.Status == 2">
                  已完成{{  scope.row.TaskProgressValue }}%
                </div>
                <div slot="content"
                     v-if="scope.row.Status == 1 || scope.row.Status == 4 || scope.row.Status == 5">
                  审核中
                </div>
                <div slot="content"
                     v-if="scope.row.Status == 6 || scope.row.Status == 3">
                  已退回
                </div>
                <div slot="content"
                     v-if="scope.row.Status == -1">
                  已取消
                </div>
                <div>
                  <el-tag v-if="scope.row.Status == 0"
                          effect="dark"> 进行中</el-tag>
                  <el-tag v-if="scope.row.Status == 2"
                          effect="dark"
                          type="success">已完成</el-tag>
                  <el-tag v-if="scope.row.Status == -1"
                          effect="dark"
                          type="info">已取消</el-tag>
                  <el-tag v-if="scope.row.Status == 3"
                          effect="dark"
                          type="danger">退回</el-tag>
                  <el-tag v-if="scope.row.Status == 1"
                          effect="dark"
                          type="warning">已完成审核中</el-tag>
                  <el-tag v-if="scope.row.Status == 4"
                          effect="dark"
                          type="warning">新建任务待审核</el-tag>
                  <el-tag v-if="scope.row.Status == 5"
                          effect="dark"
                          type="danger">取消任务待审核</el-tag>
                  <el-tag v-if="scope.row.Status == 6"
                          effect="dark"
                          type="danger">新建任务退回</el-tag>
                  <el-tag v-if="scope.row.Status == 8"
                          effect="dark"
                          type="warning">阅知审核中</el-tag>
                  <el-tag v-if="scope.row.Status == 9"
                          effect="dark"
                          type="success">审核完成</el-tag>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="任务类型"
                           prop="CommonTaskClassifyText"
                           min-width="100px"
                           :formatter="formatCommonTaskClassifyText"></el-table-column>
          <el-table-column label="任务来源"
                           prop="Source"
                           :formatter="formatSource"
                           min-width="100px"></el-table-column>
          <el-table-column label="责任人"
                           prop="PersonOfDuty"
                           min-width="100px"></el-table-column>
          <el-table-column label="创建时间"
                           prop="CreateTime"
                           min-width="100px"
                           :formatter="formatCreateTime">
            <template slot-scope="scope">
              <el-tooltip placement="top">
                <div slot="content">
                  {{ formatTipCreateTime( scope.row) }}
                </div>
                <div>
                  {{ formatCreateTime( scope.row) }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <!-- <el-table-column label="完成后需审核" prop="IsNeedApproval" align="center">
                            <template slot-scope = "scope">
                            <el-tag :type = "scope.row.IsNeedApproval ? 'danger' : 'success'"  disable-transitions>
                                {{scope.row.IsNeedApproval ? "是" : "否"}}
                            </el-tag>
                            </template>
                        </el-table-column> -->
          <!-- <el-table-column label="备注"  width="100"  prop="Remark"></el-table-column> -->
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip placement="top"
                          content="双击也可以查看任务进度喔~">
                <el-button type="text"
                           size="medium"
                           @click="checkInfo(scope.row)">查看</el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <el-pagination align='center'
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-size="pageSize"
                       layout="prev, pager, next, jumper"
                       :page-count="total"
                       :total="totaldata">
        </el-pagination>

        <el-table :data="processlist"
                  highlight-current-row
                  row-key="Id"
                  lazy
                  :indent="30"
                  :load="load"
                  :tree-props="{children: 'children',hasChildren: 'IsHasChildren'}"
                  :fit="true"
                  :row-style="{height:'40px'}"
                  :cell-style="{padding:'0px'}"
                  :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                  style="width: 100%;height:100%;">
          <el-table-column type="index"></el-table-column>
          <el-table-column label="实例号"
                           prop="INCIDENT"
                           min-width="60px"
                           show-overflow-tooltip></el-table-column>
          <el-table-column label="流程名称"
                           prop="PROCESSNAME"
                           min-width="100px"
                           show-overflow-tooltip></el-table-column>
          <el-table-column label="处理时间"
                           prop="ENDTIME"
                           min-width="60px"
                           :formatter="formatCreateTime">
            <template slot-scope="scope">
              <el-tooltip placement="top">
                <div slot="content">
                  {{ formatTipEndTime( scope.row) }}
                </div>
                <div>
                  {{ formatEndTime( scope.row) }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="节点"
                           prop="STEPLABEL"
                           min-width="170px"
                           show-overflow-tooltip></el-table-column>
        </el-table>



        <el-dialog :visible.sync="chooseDptVisable"
                   v-model="chooseDptVisable"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
                   @opened="onDptOpened"
                   style="width: 145%; left: -300px; top:-80px"
                   center>
          <template slot="title">
            <span style="color:#FFFFFF;font-size: 16px;">选择查看部门</span>
          </template>

              <el-tree
              :data="alldepartmentdata"
              show-checkbox
              :expand-on-click-node="true"
              :default-expand-all="true"
              :check-strictly="true"
              :check-on-click-node="true"
              highlight-current
              :indent="30"
              @check="handleCheck"
              node-key="id"
              ref="tree1"
            ></el-tree>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click.native="handleChooseOKDpt">选择</el-button>
            </div>
          </el-dialog>


    <taskinfo v-if="checkVisible" :data="checkForm" @closeFunction="checkclose" type="normal" ></taskinfo>


      </div>
      <div v-show="isuser"
           style="width:1000px;height: 1000px;">
        <PieCharttool :key="key"
                      :userid="userid"></PieCharttool>
      </div>
    </el-col>
  </section>
</template>
<script>
import util from '../../../util/date';
import RaddarCharttool from "../../components/RaddarCharttool"
import PieCharttool from "../../components/PieCharttool"
import BarCharttool from "../../components/BarCharttool"
import PeopleBarCharttool from "../../components/PeopleTodayLogBarCharttool"
import PeopleTaskBarCharttool from "../../components/PeopleTaskBarCharttool"
import { AddTaskCollect, RemoveTaskCollect, AddTaskProgressReview, QueryReviewByProgressId, GetChildrenList, QueryPageTodayOperatorProcessByUsercode, QueryPageTodayOperatorTasksByUsercode, QueryTasksById, QueryTaskProgressByTaskId, QueryChildTasksByParentId, GetUserTopDepartmentList, GetDepartmentPostUserList, GetYearPlanListByUserCode,QuerySearchQuanxianListByUsercode } from "../../api/oa"
import taskinfo from "../../components/taskinfo.vue"
import { GetDepartmentTree } from "../../api/hr";
import moment from 'moment-timezone'
import date from '../../../util/date';

export default {
  components: { taskinfo, RaddarCharttool, PieCharttool, BarCharttool, PeopleBarCharttool, PeopleTaskBarCharttool },
  data () {
    return {
      btntext:'选择部门',
      chooseDptVisable:false,
      alldepartmentdata:[],
      currentActiveId: null,
      lookdate: moment(new Date()).format('YYYY/MM/DD'),
      Pagination: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      processtotal: null,
      processtotaldata: null,
      processlist: [],
      pageSize: 15, // 每页的数据条数
      page: 1,
      DepartcurrentPage: 1, // 当前页码
      Departtotal: null, // 总条数
      Departtotaldata: null,
      DepartpageSize: 20, // 每页的数据条数
      Departpage: 1,
      departmentsearchdata: [],
      TypeIdModules: [],   //部门类型集合
      users: [],
      departusers: [],
      departmentList: [],
      DptProps: {
        label: 'Name',
        isLeaf: 'Leaf'
      },
      currentRow: null,
      DepartUserRow: null,
      workItem: null,
      taskClassify: 0,
      taskStatus: 0,
      progressValue: null,
      deptinput: null,
      madedate: null,
      cascadervalue: null,
      ClassifyModules: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: "日常工作"
        },
        {
          value: 2,
          label: "跨部门协调"
        },
        {
          value: 3,
          label: "例会项目"
        },
        {
          value: 4,
          label: "年度计划"
        },
        {
          value: 5,
          label: "专项会议"
        },
      ],
      StatusModules: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: "进行中"
        },
        // {
        //     value: 1,
        //     label: "已完成审核中"
        // },
        {
          value: 2,
          label: "已完成"
        },
        // {
        //     value: 3,
        //     label: "退回"
        // },
        // {
        //     value: 4,
        //     label: "新建任务待审核"
        // },
        // {
        //     value: 5,
        //     label: "取消任务待审核"
        // },
        // {
        //     value: 6,
        //     label: "新建任务退回" 
        // },

      ],
      checkVisible: false,
      checkForm: {            //查看表单初始化
        Id: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null
      },
      showAssign: false,
      createPerson: false,                    //创建人是否显示
      showyearplan: false,                    //年度计划查看界面显示控制
      showorganize: false,                     //跨部门协同查看界面显示控制
      showregularproject: false,               //例会来源查看界面控制
      showprofessionmeetong: false,            //专项会议查看界面控制
      CommonTaskClassifyText: null,
      pValue1: null,   //进度条数值
      istaskpro: false,
      tasks: [],
      content:'',
      mapReview:new Map(),
      options: [],
      checkdepartid: null,
      departuserid: null,
      props: {
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          // console.log(resolve);
          const { level, value } = node;
          if (node.level == 0) {

            var user = JSON.parse(window.localStorage.user);
            var userid = user.sub;
            QuerySearchQuanxianListByUsercode({ usercode: userid }).then(res => {
              // console.log(res);
              var dataArr=res.data.response;
              var nodes=new Array();
              var n=0;
              
              for(var d=0;d<dataArr.length;d++)
              {
                if(dataArr[d].Type==99 || dataArr[d].Type==2)
                {
                  var dptIds=dataArr[d].DepartmentCode;
                  var dptNames=dataArr[d].DepartmentName;
                  var dptIdArr=dptIds.split(',');
                  var dptNameArr=dptNames.split(',');
                  for(var a=0;a<dptIdArr.length;a++)
                  {
                    if(dptIdArr[a].length>0)
                    {
                      var obj={};
                      obj.label=dptNameArr[a];
                      obj.value=dptIdArr[a];
                      obj.leaf=false;
                      nodes=nodes.filter(function (o, index, array) {
                          return o.value!=obj.value
                      })
                      nodes.push(obj);
                    }
                  }
                  n++;
                }
              }
              
              if(n>0)
              {
                resolve(nodes);
                if(nodes.length>0)
                {
                  var value=nodes[0].value;
                  self.topDepartment=value;
                }
              }else{
                GetUserTopDepartmentList({ userId: userid }).then(res => {
                  // console.log(res);
                  const nodes = res.data.data.map(x=>({
                      value: x.deptId,
                      label: x.deptName,
                      leaf: !x.hasNode
                  }))
                  resolve(nodes);
                  if(res.data.data.length>0)
                  {
                    var value=res.data.data[0].Id;
                    self.topDepartment=value;
                  }
                });
              }
              
              // resolve(nodes);
              // if(nodes.length>0)
              // {
              //   var value=nodes[0].value;
              //   self.topDepartment=value;
              // }
            });





            // var user = JSON.parse(window.localStorage.user);
            // var userid = "";
            // if (user.name == '马玲' || user.name == '杨光') {
            //   userid = 'b57e4ab5-7df4-482b-a8f3-a36f012b963f';
            // }
            // else if (user.name == '刘丽丽' || user.name == '尹滕滕' || user.name == '李欣晟')//销售工程公司
            // {
            //   userid = '971e3fb0-f33a-45d1-bc28-a36f012b8df2';
            // }
            // else if (user.name == '田婷婷' || user.name == '张刚')
            // {
            //   userid = '33824a20-4d5e-4498-8786-a36f012b95e1';
            // }
            // else if (user.name == '韩庆')//人防公司
            // {
            //   userid = 'f919ed9f-8660-44aa-96aa-a36f012b8cc7';
            // }
            // else if (user.name == '谢娇')//洁净空调
            // {
            //     userid = 'ab753a23-e2ce-4c0d-96f3-a36f012b8892';
            // }
            // else if (user.name == '苏兴家')//能源
            // {
            //     userid = '898dacfe-b63c-4b22-9646-a36f012b8d16';
            // }
            // else if (user.name == '路来亮')//通风二
            // {
            //     userid = '679337b8-ccea-4449-9460-a36f012b8c27';
            // }
            // else if (user.name == '孙成伟')//安装
            // {
            //     userid = 'db7992cd-88fa-4f1d-8557-a36f012b8cfa';
            // }
            // else if (user.name == '陈洪星')//通风一
            // {
            //     userid = '4a745436-1600-4dc6-85da-a36f012b8665';
            // }
            // else if (user.name == '曹洪金')//通风配件
            // {
            //     userid = '8376b7af-d8fd-4d72-9545-9cf3de637bfe';
            // }
            // else if (user.name == '韩朝晖')//环保一、活性炭
            // {
            //     userid = '9928b2e3-61ec-4a8a-8067-a36f012b886d';
            // }
            // else if (user.name == '邵小红')//风电
            // {
            //     userid = '33824a20-4d5e-4498-8786-a36f012b95e1';
            // }
            // else if (user.name == '陈卫卫')//管业
            // {
            //     userid = 'ff176d0e-4213-493f-9be4-a36f012b942e';
            // }
            // else if (user.name == '李沛沛')//复材制品
            // {
            //     userid = 'dcffef71-be23-4555-ab3d-a36f012b9559';
            // }
            // else if (user.name == '涂美铃')//复材科技
            // {
            //     userid = '00861836-d4cf-4d23-b3f4-526fe507b59e';
            // }
            // else if (user.name == '白茹')//公共管理中心
            // {
            //     userid = '418207c2-c8cd-403b-b719-a36f012b968e';
            // }
            // else if (user.name == '康佳')//公共管理中心
            // {
            //     userid = '418207c2-c8cd-403b-b719-a36f012b968e';
            // }
            // else if (user.name == '贾欣慧')
            // {
            //     userid = '418207c2-c8cd-403b-b719-a36f012b968e';
            // }
            // else if(user.name == '刘绪璐')
            // {
            //     userid = '0b84fc71-ade2-46d9-814f-a36f012b8be1';
            // }
            // else {
            //   userid = user.sub;
            // }
            
          } else {
            GetChildrenList({deptId: node.value}).then(res => {
                // console.log(res);
                var mydata = res.data.data;
                if(mydata.dept[0].children!=null)
                {
                    const nodes = res.data.data.dept[0].children.map(x=>({
                        value: x.id,
                        label: x.label,
                        leaf: !x.hasChildren
                    }))
                    // console.log(nodes);
                    resolve(nodes)   
                }else{
                    resolve([]);
                }
            })
          }

        }
      },
      YearPlanTaskList: [],
      YearPlanTask: null,
      YearPlanTaskId: null,
      isdepartment: true,
      departid: [],
      key: 0,
      userid: '',
      isuser: false,
      collectBtn:false,
      collectRowId:''
    }
  },
  methods: {
    handleChangeDay(type)
    {
      if(type==1)
      {
        var result = moment(this.lookdate).add(-1, 'days');
        this.lookdate = result;
        setTimeout(() => {
          this.handleDateChange(this.lookdate);
        }, 300);
      }else if(type==2)
      {
        var result = moment(this.lookdate).add(1, 'days');
        this.lookdate = result;
        setTimeout(() => {
          this.handleDateChange(this.lookdate);
        }, 300);
      }
    },
    handleCheck(val,node)
    {
      // console.log(val);
      // console.log(node);
      let self=this;
      self.myoptions=[];
      self.departusers=[];
      for(var a=0;a<node.checkedNodes.length;a++)
      {
        var item = node.checkedNodes[a];
        if(
          item.label == '格瑞德集团' || 
          item.label == '董事会' || 
          item.label == '集团公司'
        ){
          this.$message({
            message: '因查阅数据较大，您不能直接选择顶级部门进行查询',
            type: 'info'
          });
          for(var n=0;n<node.checkedNodes.length;n++)
          {
            this.$refs.tree1.setChecked(node.checkedNodes[n],false);
          }
          // this.$refs.tree1.setCheckedKeys([]);
          // this.$refs.tree1.setCurrentKey(null);

          break;
        }else{

          if(node.checkedNodes.length>1)
          {
            this.$message({
              message: '由于图表横向展示人数有限，请选择一个部门节点进行查看',
              type: 'info'
            });
            this.$refs.tree1.setChecked(node.checkedNodes[1],false);
            return;
          }
          // console.log(a);
          // console.log(node.checkedNodes[0]);
          var dptid = item.id;
          // console.log(dptid);
          // console.log(dptid);
          // self.getUser(dptid);
        }
      }
    },
    handleCollect(row)
    {
      if(row.CollectId==null)
      {
        var para={};
        para.TaskID=row.Id;
        var user = JSON.parse(window.localStorage.user);
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para)
        // return;
        AddTaskCollect(para).then((res) => {
          this.$message({
            message: '已特别关注',
            type: 'success'
          });
          // console.log(res);
          var id=res.data.response;
          row.CollectId=id;
        })
      }else{
        this.$confirm("确定要关闭对此任务的关注吗？", "提示", {}).then(() => {
          var para={};
          para.Id=row.CollectId;
          para.TaskID=row.Id;
          RemoveTaskCollect(para).then((res) => {
            this.$message({
              message: '已取消关注',
              type: 'success'
            });
            row.CollectId=null;
          })
        }).catch(()=>{});
      }
    },
    tabmouseEnter(row, column, cell, event)
    {
      this.collectBtn=true;
      this.collectRowId=row.Id;
      // console.log(row)
    },
    tabmouseLeave(row, column, cell, event)
    {
      this.collectBtn=false;
      this.collectRowId='';
    },
    handleChange(val)
    {
      // console.log(val)
      if(val.length>0)
      {
        var progressId = val[0];
        this.loadReview(progressId);
      }
    },
    catchData(c)
    {
      // console.log(c)
      this.content=c;
    },
    handlePopoShow()
    {
      
    },
    handlePopoReplyShow()
    {

    },
    myname()
    {
      var user = JSON.parse(window.localStorage.user);
      return user.name;
    },
    handleReplyReview(progress,rid)
    {
      // console.log(progress);
      // console.log(rid);
      let self=this;
      var progressId = progress.Id;
      var user = JSON.parse(window.localStorage.user);
      var para={};
      para.TaskID=this.checkForm.Id;
      para.ProgressID=progressId;
      para.ReplayID = rid;
      para.ReviewContent = self.content;
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      // console.log(para);

      AddTaskProgressReview(para).then((res) => {
        // console.log(res);
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          for(var a=0;a<self.tasks.length;a++)
          {
            if(this.tasks[a].Id==progressId)
            {
              this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
              break;
            }
          }
          this.loadReview(progressId);
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    handleSendReview(progress)
    {
      let self=this;
      // this.$confirm('评论即将发表，确定吗？', '提示', {}).then(() => 
      {
        var progressId = progress.Id;
        var user = JSON.parse(window.localStorage.user);
        var para={};
        para.TaskID=this.checkForm.Id;
        para.ProgressID=progressId;
        para.ReplayID = null;
        para.ReviewContent = self.content;
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para);

        AddTaskProgressReview(para).then((res) => {
          // console.log(res);
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            for(var a=0;a<self.tasks.length;a++)
            {
              if(this.tasks[a].Id==progressId)
              {
                this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
                break;
              }
            }
            this.loadReview(progressId);
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      }
      // )
    },
    loadReview(progressid)
    {
      let self=this;
      // console.log(progressid)
      let para = { progressId: progressid };
      QueryReviewByProgressId(para).then((res) => {
        // console.log(res)
        var data = res.data.response;
        // console.log(data);
        self.mapReview.set(progressid,data);
        // console.log(self.mapReview);
        this.$forceUpdate()
        // for(var a=0;a<data.length;a++)
        // {
        //   self.mapReview.set(progressid,data);
        // }
      });
    },
    getReplyReview(arr,id)
    {
      // console.log(arr);
      // console.log(id);
      var o ={
        CreatedBy:'',
        CreatedId:'',
        CreatedTime:'',
        ReviewContent:''
      };
      for(var i=0;i<arr.length;i++)
      {
        if(arr[i].Id==id)
        {
          o = arr[i];
          break;
        }
      }
      return o;
    },
    handleProcessSelectChange (taskid, index) {
      this.currentActiveId = taskid;
      this.currentProgressRowIndex = index;
    },
    showTaskFile (file) {
      window.open(file, "_blank");
    },
    getLookDate: function () {
      return this.lookdate;
    },
    handleDateChange (v) {
      // console.log('v'+new Date(v));
      // let d= new Date(v);
      // this.lookdate=d.getFullYear()+'/'+(d.getMonth()+1)+'/'+d.getDate();
      // console.log(this.lookdate);
      var aa=moment(new Date(v)).format('YYYY/MM/DD');
      // console.log(aa)
      this.lookdate=aa;
      if (this.checkdepartid != null) {
        this.getDepartUsers(this.checkdepartid);
      }
    },
    call (arr) {
      // console.log();
      this.lookdate=moment(this.lookdate).format('YYYY/MM/DD');
      // if(this.lookdate.indexOf('-'))
      // {
      //   this.lookdate=this.lookdate.replace('-','/');
      // }
      // if(this.lookdate.toString().indexOf('T')>0)
      // {
      //   var arr =this.lookdate.split('-');
      //   this.lookdate=arr[0]+'/'+arr[1]+'/'+arr[2];
      // }
      let para = {
        userCode: arr[0],
        date: this.lookdate,
        pageIndex: this.page,
        pageSize: this.pageSize
      };
      // console.log(para);
      // return;
      QueryPageTodayOperatorTasksByUsercode(para).then((res) => {
        //console.log(res)
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
      });
      var username = arr[1];
      let para2 = {
        userCode: username,
        date: this.lookdate,
        pageIndex: this.page,
        pageSize: this.pageSize
      };
      //console.log(para2)
      QueryPageTodayOperatorProcessByUsercode(para2).then((res) => {
        //console.log(res)
        this.processtotal = res.data.response.pageCount;
        this.processtotaldata = res.data.response.dataCount;
        this.processlist = res.data.response.data;
      });
    },
    handleChooseOKDpt()
    {
      var nodes = this.$refs.tree1.getCheckedNodes();
      // console.log(nodes);
      this.chooseDptVisable=false;
      this.checkdepartid = nodes[0].id;
      this.btntext=nodes[0].deptName;
      // this.departid = nodes[0].id;
      // this.cascaderchange();
      this.getDepartUsers(this.checkdepartid);

    },
    handleChooseDpt()
    {
      this.alldepartmentdata=[];
      this.chooseDptVisable=true;
    },
    onDptOpened()
    {
      let self=this;
      var user = JSON.parse(window.localStorage.user);
      var userid = user.sub;
      QuerySearchQuanxianListByUsercode({ usercode: userid }).then(res => {
        // console.log(res);
        var dataArr=res.data.response;
        var nodes=new Array();
        var n=0;
        for(var d=0;d<dataArr.length;d++)
        {
          if(dataArr[d].Type==99 || dataArr[d].Type==2)
          {
            var dptIds=dataArr[d].DepartmentCode;
            var dptNames=dataArr[d].DepartmentName;
            var dptIdArr=dptIds.split(',');
            var dptNameArr=dptNames.split(',');
            for(var a=0;a<dptIdArr.length;a++)
            {
              if(dptIdArr[a].length>0)
              {
                GetDepartmentTree({deptId:dptIdArr[a]}).then((res) => {
                  if (res.data.code==200) {
                    for(var d=0;d<res.data.data.dept.length;d++)
                    {
                      self.alldepartmentdata.push(res.data.data.dept[d]);
                    }
                  }
                });
              }
            }
            n++;
          }
        }
        if(n==0)
        {
          GetUserTopDepartmentList({ userId: userid }).then(res => {
            res.data.data.forEach(element => {
                GetDepartmentTree({deptId:element.deptId}).then((res) => {
                  if (res.data.code==200) {
                    for(var d=0;d<res.data.data.dept.length;d++)
                    {
                      self.alldepartmentdata.push(res.data.data.dept[d]);
                    }
                  }
                });
            });
          });
        }
      })
    },
    checkclose () {
      this.checkVisible = false;
      this.pValue1 = 0;
    },
    //双击查看详细信息
    checkInfo (row) {
      // console.log(row)
      this.checkVisible = true;
      this.checkForm = row;
      return;
      if (row.TaskClassify == 1) {
        // this.CommonTaskClassifyText = row.CommonTaskClassifyText;
        this.CommonTaskClassifyText = "日常工作";
        this.showorganize = false;
        this.showyearplan = false;
        this.showregularproject = false;
        this.showprofessionmeetong = false;
        if (row.PersonOfDuty != row.CreatedBy) {
          this.showAssign = true;
        } else {
          this.showAssign = false;
        }
        this.createPerson = true;
      } else {
        if (row.TaskClassify == 2) {
          this.CommonTaskClassifyText = "跨部门协调";
          this.showorganize = true;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = false;
        }
        else if (row.TaskClassify == 3) {
          this.CommonTaskClassifyText = "例会项目";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = true;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = true;
        } else if (row.TaskClassify == 4) {
          this.CommonTaskClassifyText = "年度计划";
          this.showorganize = false;
          this.showyearplan = true;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = false;
        } else if (row.TaskClassify == 5) {
          this.CommonTaskClassifyText = "专项会议";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = true;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = true;
        }
        else {
          this.CommonTaskClassifyText = "其他";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = false;
        }
      }
      QueryTasksById({ Id: row.Id, taskClassify: row.TaskClassify }).then((res) => {
        this.checkForm = res.data.response;
        this.checkForm.Files = [];
        if (res.data.response.FileName != null) {
          var arr = res.data.response.FileName.split(",");
          var arr2 = res.data.response.FileAddress.split(",");
          var files = [];
          for (var a = 0; a < arr.length; a++) {
            var f = {};
            f.FileName = arr[a];
            f.FileAddress = arr2[a];
            files.push(f);
          }
          this.checkForm.Files = files;
        }
      })
      this.pValue1 = row.TaskProgressValue;
      //   this.checkForm = Object.assign({},row);
      let para = { taskId: row.Id };
      QueryTaskProgressByTaskId(para).then((res) => {
        //   this.tasks = res.data.response;
        var data = res.data.response;
        let formatData = new Array();
        for (var a = 0; a < data.length; a++) {
          var element = data[a];
          element.Files = [];
          if (element.FileName != null) {
            var arr = element.FileName.split(",");
            var arr2 = element.FileAddress.split(",");
            var files = [];
            for (var d = 0; d < arr.length; d++) {
              var f = {};
              f.FileName = arr[d];
              f.FileAddress = arr2[d];
              files.push(f);
            }
            element.Files = files;
          }
          formatData.push(element);
        }
        this.tasks = formatData;
        this.$nextTick(function () {
          var div = document.getElementsByClassName("tasksdiv");
          if (div && 0 < div.length) {
            div[0].scrollTop = div[0].scrollHeight;
          }
        })
      });
    },
    selectDepartUserRow (val) {
      console.log(val);
      this.DepartUserRow = val;
      this.departuserid = val.UserId;
      this.YearPlanTaskId = null;
      this.getManagerChecks();
      let para = { userCode: val.UserId };
      // GetYearPlanListByUserCode(para).then((res) => {
      //     this.YearPlanTaskList = res.data.response;
      // });
      // this.isuser = true;
      // this.isdepartment = false;
      // this.userid = val.UserId;
      // this.key += 1;
    },
    YearPlanchange (val) {
      this.YearPlanTask = val.split('|')[2];
      this.YearPlanTaskId = val.split('|')[1];
    },
    load (tree, treeNode, resolve) {
      let para = {
        taskId: tree.Id,
      };
      QueryChildTasksByParentId(para).then((res) => {
        resolve(res.data.response)
      });
    },
    formatPlanComplateTime: function (row, colume) {
      return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime), 'yyyy-MM-dd');
    },
    formatCreatedOn: function (row, colume) {
      return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn), 'yyyy-MM-dd hh:mm');
    },
    formatCommonTaskClassifyText: function (row, colume) {
      if (row.TaskClassify == 1) {
        // return row.CommonTaskClassifyText;
        return "日常工作";
      } else {
        if (row.TaskClassify == 2) {
          return "跨部门协调";
        }
        else if (row.TaskClassify == 3) {
          return "例会项目";
        } else if (row.TaskClassify == 4) {
          return "年度计划";
        } else if (row.TaskClassify == 5) {
          return "专项会议";
        }
        else {
          return "其他";
        }
      }
    },
    formatSource: function (row, column) {
      return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
    },
    formatCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
    },
    formatTipCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime), 'yyyy-MM-dd hh:mm');
    },
    formatTipEndTime: function (row, column) {
      return (!row.ENDTIME || row.ENDTIME == '') ? '' : util.formatDate.format(new Date(row.ENDTIME), 'yyyy-MM-dd hh:mm');
    },
    formatEndTime: function (row, column) {
      return (!row.ENDTIME || row.ENDTIME == '') ? '' : util.formatDate.dateformat(new Date(row.ENDTIME));
    },
    //翻页处理
    handleCurrentChange (val) {
      this.page = val;
      this.getManagerChecks();
      this.page = 1;
    },
    getManagerChecks () {
      //时间转化
      let begintime = "";  //开始时间
      let endtime = "";    //结束时间
      if (this.madedate != null) {
        switch (this.madedate.length) {
          case 1:
            begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
            break;
          case 2:
            begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
            endtime += util.formatDate.format(new Date(this.madedate[1]), 'yyyy-MM-dd');
            break;
          default:
            break;
        }
      }
      if (this.departuserid == null) {
        this.$message({
          message: '请先选择想要查看任务的部门人员',
          type: 'warning',
          duration: 7000
        });
        return;
      }
      let para = {
        userCode: this.departuserid,
        workItem: this.workItem,
        taskClassify: this.taskClassify,
        taskStatus: this.taskStatus,
        begin: begintime,
        end: endtime,
        yearplanId: this.YearPlanTaskId,
        progressValue: this.progressValue,
        pageIndex: this.page,
        pageSize: this.pageSize
      };
      QueryPageTodayOperatorTasksByUsercode(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
      });
    },
    cascaderchange (node) {
      // 目的是选择之后将下拉界面收起
      this.$refs.department.toggleDropDownVisible();
      this.Pagination = false;
      let pathvalue = this.$refs.department.getCheckedNodes()[0];
      this.checkdepartid = pathvalue.value;
      this.getDepartUsers(pathvalue.value);
      this.isdepartment = true;
      this.isuser = false;
      // this.key += 1;
      this.$refs.department.dropDownVisible = false;
    },
    handleCurrentChangeDepart (val) {
      if (this.Departpage + val <= 0) {
        this.Departpage = 1;
        this.$message({
          message: '已到首页',
          type: 'warning',
          duration: 7000
        });
        return;
      }
      console.info(this.Departpage);
      console.info(this.Departtotal);
      if (this.Departtotal < this.Departpage + val) {
        this.$message({
          message: '已到末页',
          type: 'warning',
          duration: 7000
        });
        return;
      }
      this.Departpage = this.DepartcurrentPage + val;
      this.getDepartUsers(this.checkdepartid);
      // this.Departpage = 1;
      //    this.key += 1;
    },
    //获取部门人员
    getDepartUsers (node) {
      let para = {
        deptId: node,
        pageNum: this.Departpage,
        // size: this.DepartpageSize
        isMainJob: true,
        isAllNextDept:false,
        pageSize:1000
      }
      // console.log(para);
      // return;
      GetDepartmentPostUserList(para).then(res => {
        var data = res.data.data.list;
        // console.log(data)
        let newdata = this.unique(data);
        let arr=[];
        newdata.forEach(element => {
            if(element.workTypeName=='后勤')
            {
                arr.push(element);
            }
        });
        // this.departusers = newdata;
        this.departusers = arr;
        this.Pagination = true;
        // this.departid = newdata;
        this.departid = arr;
        // this.departid = res.data.response.data;
        this.key += 1;
        return;
        // this.Departtotal = res.data.response.pageCount;
        // this.Departtotaldata = res.data.response.dataCount;
        this.users = [];
        this.processlist = [];
      });

    },
    unique (arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    }

  },
  mounted () {

  }
}
</script>
<style scoped>
.orgtree {
  /* overflow-y: auto;
  overflow-x: scroll; */
  height: calc(100vh - 280px);
  width: 200px;
  border: 0px solid blue;
  padding-top: 35px;
}
.orgbtndivtree {
  padding-left: 20px;
  padding-right: 20px;
}
/deep/.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}
/deep/input:disabled,
/deep/textarea:disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(12, 12, 12);
}
/deep/.el-table .warning-row {
  background: #f0f8ff;
}
/deep/.el-button--goon {
  color: #fff;
  background-color: #20b2aa;
  border-color: #20b2aa;
}
/deep/.el-button--goon:hover {
  background: #48d1cc;
  border-color: #48d1cc;
  color: #fff;
}
/deep/.el-button--goon:focus {
  background: #20b2aa;
  border-color: #20b2aa;
  color: #fff;
}

.managercheckdiv,
.managercheckcard {
  height: calc(100vh - 220px);
}
.tasksdiv {
  max-height: calc(100vh - 350px);
  overflow: auto;
  padding-left: 2px;
}
.active {
  background-color: #00000014;
}
.eldropdownitem,
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
</style>